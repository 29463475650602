import * as React from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import { useState } from "react";
import styled from "@emotion/styled";
import { colors, screenSize } from "../../utils/css/themes";
import { useStaticQuery, graphql, navigate } from "gatsby";

const InputContainer = styled.div`
  display: block;
  margin: auto;
  margin-bottom: 2rem;
  width: 100%;
`;

const Input = styled.input`
  width: 100%;
`;

const Submit = styled.button`
  display: block;
  background-color: white;
  color: ${colors.blue};
  border: 1px solid ${colors.blue};
  width: 100%;
  margin-top: 1rem;
  border-radius: 1rem;
  &:focus,
  &:hover {
    background-color: ${colors.blue};
    color: white;
  }
`;
const Label = styled.label`
  display: block;
`;

const SearchContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  @media (max-width: ${screenSize.largeTablet}) {
    width: 100%;
  }
`;

const Select = styled.select`
  color: ${colors.blue};
  background-color: white;
  font-size: 10px;
  border: solid 1px #d7dae1;
  width: 100%;
  font-size: 1rem;
  height: 37px;
`;

const search = () => {
  
  const data = useStaticQuery(graphql`
    {
      issue_pages: allWpPost(
        filter: {
          categories: { nodes: { elemMatch: { name: { eq: "Issue Page" } } } }
        }
        sort: { fields: title, order: ASC }
      ) {
        distinct(field: title)
      }
    }
  `);

  const issues = data?.issue_pages?.distinct;

  const [search, setSearch] = useState("");
  const [issue, setIssue] = useState("");

  const handleSubmit = (e: any) => {
    e.preventDefault();
    navigate("../../search-for-an-organization/results", {
      state: { search, issue },
    });
  };

  return (
    <Layout title={"Search for an Organization"}>
      <SEO title="Search for an Organization" />
      <SearchContainer>
        <FormContainer onSubmit={(e) => handleSubmit(e)}>
          <InputContainer>
            <Label htmlFor="keywords">Organization Name</Label>
            <Input
              id="keywords"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            ></Input>
          </InputContainer>
          <InputContainer>
            <Label htmlFor="company_issue">{"Choose a Community issue"}</Label>
            <Select
              id="company_issue"
              value={issue}
              onChange={(e) => setIssue(e.target.value)}
            >
              <option key={-1} value={""}>
                {""}
              </option>
              {issues?.map((issue: any, i: number) => (
                <option key={i} value={issue}>
                  {issue}
                </option>
              ))}
            </Select>
          </InputContainer>
          <InputContainer>
            <Submit type="submit">Go!</Submit>
          </InputContainer>
        </FormContainer>
      </SearchContainer>
    </Layout>
  );
};

export default search;
